import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';

const Buttons = styled.div`
  background-color: ${colors.purple800};
  border-radius: 32px;
  height: 54px;
  margin: 36px auto 0;
  padding: 4px;
  width: max-content;
`;

const Button = styled.button<{ active?: boolean }>`
  background-color: ${({ active }) =>
    active ? `${colors.white}` : 'transparent'};
  border: none;
  border-radius: 34px;
  color: ${({ active }) => (active ? `${colors.black}` : `${colors.white}`)};
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  padding: 0 29px;
  width: max-content;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ToggleProps {
  firstToggleButton: string;
  firstToggleContent: React.ReactNode;
  secondToggleButton: string;
  secondToggleContent: React.ReactNode;
}

const Toggle: FC<ToggleProps> = ({
  firstToggleButton,
  firstToggleContent,
  secondToggleButton,
  secondToggleContent,
}) => {
  const [activeToggle, setActiveToggle] = useState<string>('toggle-1');

  return (
    <>
      <Buttons>
        <Button
          onClick={() => setActiveToggle('toggle-1')}
          active={activeToggle === 'toggle-1'}
        >
          {firstToggleButton}
        </Button>
        <Button
          onClick={() => setActiveToggle('toggle-2')}
          active={activeToggle === 'toggle-2'}
        >
          {secondToggleButton}
        </Button>
      </Buttons>
      {activeToggle === 'toggle-1' ? (
        <Content>{firstToggleContent}</Content>
      ) : (
        <Content>{secondToggleContent} </Content>
      )}
    </>
  );
};

export default Toggle;
