import React, { FC } from 'react';
import styled from 'styled-components';

import { device } from 'src/theme/breakpoints';

const Wrapper = styled.div`
  display: flex;
  font-size: 14px;
`;

const IconWrapper = styled.div`
  height: auto;
  margin-right: 12px;
  width: 18px;

  @media ${device.desktop} {
    width: 22px;
  }
`;

interface IconProps {
  children: string | React.ReactNode;
  icon: React.ReactNode;
  className?: string;
}

const Icon: FC<IconProps> = ({ children, icon, className }) => (
  <Wrapper className={className}>
    <IconWrapper>{icon}</IconWrapper>
    {children}
  </Wrapper>
);

export default Icon;
