import styled from 'styled-components';
import React, { FC, useState } from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import IconBlock from 'src/components/IconBlock';
import {
  RewindIcon,
  LampIcon,
  GlobeIcon,
  ClockIcon,
  CodingIcon,
  NoCardIcon,
  DesktopIcon,
  EuroIcon,
  MarkIcon,
  ThunderIcon,
  PCIcon,
  MaintenanceIcon,
  SecurityCircleIcon,
} from 'src/assets/icons';
import Grid from 'src/components/Grid';
import Section from 'src/components/Section';
import { colors } from 'src/theme/colors';
import Content from 'src/components/Content';
import Bundle from 'src/components/Bundle';
import List from 'src/components/List';
import { device } from 'src/theme/breakpoints';
import Heading, { HeadingVariant } from 'src/components/Heading';
import Button from 'src/components/Button';
import Modal from 'src/components/Modal';
import ContactForm from 'src/components/ContactForm';
import Icon from 'src/components/Icon';
import PricePlan from 'src/components/PricePlan';
import Toggle from 'src/components/Toggle';
import { ButtonAppearance } from 'src/components/Button/constants';

const Hero = styled(Content)`
  align-items: flex-start;
  display: flex;
  padding-top: 60px;
`;

const HeroImage = styled.div`
  flex: 0 1 728px;
  margin-left: 50px;

  @media ${device.desktop} {
    flex: 0 1 886px;
  }
`;

const HeroList = styled(List)`
  margin: 24px 0 30px;

  & li {
    font-size: 16px;
  }
`;

const HeroHeading = styled(Heading)`
  max-width: 460px;

  @media ${device.desktop} {
    max-width: 610px;
  }
`;

const HeroPrice = styled.span`
  font-size: 57px;
  font-weight: 700;
  line-height: 1;

  @media ${device.desktop} {
    font-size: 70px;
  }
`;

const HeroButton = styled(Button)`
  margin: 18px 0 8px;

  @media ${device.desktop} {
    margin: 18px 0;
  }
`;

const GreyBackground = styled.div`
  background-color: ${colors.grey100};
  margin-top: 62px;
  padding: 130px 0;
`;

const StyledGrid = styled(Grid)`
  margin-top: 30px;
  row-gap: 30px;

  @media ${device.laptopSUP} {
    margin-top: 70px;
    row-gap: 70px;
  }

  & > :last-child:nth-child(3n-2) {
    grid-column-end: 3;
  }
`;

const DarkBackgroundSection = styled.div`
  background-color: ${colors.purple400};
  margin-bottom: 102px;
  padding: 84px 0 94px;
  width: 100%;

  & p {
    color: ${colors.white};
  }

  @media ${device.desktop} {
    margin-bottom: 122px;
    padding: 98px 0 118px;
  }
`;

const Separator = styled.div`
  background-color: ${colors.grey500};
  height: 1px;
  margin: 48px 0;
  width: 100%;
`;

const NoteWrapper = styled.div`
  background-color: ${colors.blue100};
  border-radius: 10px;
  margin-top: 40px;
  padding: 14px;
`;

const Price = styled.div`
  font-size: 18px;
  font-weight: 700;

  @media ${device.desktop} {
    font-size: 23px;
  }
`;

const FeaturesButton = styled(Button)`
  margin: 80px auto 0;
`;

const StarterPlanSolution1ListItems = [
  {
    text: 'Buying Experience | Standard',
    icon: 'yellow',
    price: '€ 9.00',
  },
  {
    text: 'Product Search & Filtering',
    icon: 'green',
    price: '€ 0.00',
  },
  {
    text: 'Product Viewing',
    icon: 'green',
    price: '€ 0.00',
  },
  {
    text: 'Payment & Delivery | Standard',
    icon: 'yellow',
    price: '€ 0.00',
  },
  {
    text: 'Marketing Tools | Standard',
    icon: 'yellow',
    price: '€ 0.00',
  },
  {
    text: 'SEO Tools',
    icon: 'green',
    price: '€ 0.00',
  },
  {
    text: 'Analytics Tools',
    icon: 'green',
    price: '€ 0.00',
  },
];

const StarterPlanSolution2ListItems = [
  {
    text: 'Catalogue & Product Management | Standard',
    icon: 'green',
    price: '€ 0.00',
  },
  {
    text: 'Product content | Entry',
    icon: 'yellow',
    price: '€ 0.00',
  },
  {
    text: 'Advanced Price Management',
    icon: 'red',
    price: '€ 0.00',
  },
];

const StarterPlanSolution3ListItems = [
  {
    text: 'Up to 5 integrations with suppliers',
    icon: 'yellow',
    price: '€ 0.00',
  },
  {
    text: 'Integration with price comparison sites',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: '1 integration with payment processing system',
    icon: 'yellow',
    price: '€ 9.00',
  },
  {
    text: 'Integration with PayPal',
    icon: 'red',
    price: '€ 9.00',
  },
  {
    text: 'Integration with finance leasing company',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Google Analytics integration',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: '1 integration with logistics partner',
    icon: 'yellow',
    price: '€ 9.00',
  },
  {
    text: 'Integration with ERP solution',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Integration with email marketing platform',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Facebook Integration',
    icon: 'red',
    price: '€ 0.00',
  },
];

const StarterPlanSolution4ListItems = [
  {
    text: 'Sales Order Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Purchase Order Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Invoice Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Accounts Payable Processing',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Warehouse Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Payments Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Pre-Accounting',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Task Management',
    icon: 'yellow',
    price: '€ 0.00',
  },
  {
    text: 'Customer Support',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Security And Access Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Production Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Client Management',
    icon: 'yellow',
    price: '€ 0.00',
  },
];

const BusinessPlanSolution1ListItems = [
  {
    text: 'Buying Experience | Premium',
    icon: 'yellow',
    price: '€ 39.00',
  },
  {
    text: 'Product Search & Filtering',
    icon: 'green',
    price: '€ 0.00',
  },
  {
    text: 'Product Viewing',
    icon: 'green',
    price: '€ 0.00',
  },
  {
    text: 'Payment & Delivery | Standard',
    icon: 'yellow',
    price: '€ 0.00',
  },
  {
    text: 'Marketing Tools | Standard',
    icon: 'yellow',
    price: '€ 0.00',
  },
  {
    text: 'SEO Tools',
    icon: 'green',
    price: '€ 0.00',
  },
  {
    text: 'Analytics Tools',
    icon: 'green',
    price: '€ 0.00',
  },
];

const BusinessPlanSolution2ListItems = [
  {
    text: 'Catalogue & Product Management | Standard',
    icon: 'green',
    price: '€ 0.00',
  },
  {
    text: 'Product content | Mega',
    icon: 'yellow',
    price: '€ 49.00',
  },
  {
    text: 'Advanced Price Management',
    icon: 'green',
    price: '€ 9.00',
  },
];

const BusinessPlanSolution3ListItems = [
  {
    text: 'Up to 10 integrations with suppliers',
    icon: 'yellow',
    price: '€ 49.00',
  },
  {
    text: 'Integration with price comparison sites',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: '1 integration with payment processing system',
    icon: 'yellow',
    price: '€ 9.00',
  },
  {
    text: 'Integration with PayPal',
    icon: 'red',
    price: '€ 9.00',
  },
  {
    text: 'Integration with finance leasing company',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Google Analytics integration',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: '1 integration with logistics partner',
    icon: 'yellow',
    price: '€ 9.00',
  },
  {
    text: 'Integration with ERP solution',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Integration with email marketing platform',
    icon: 'green',
    price: '€ 0.00',
  },
  {
    text: 'Facebook Integration',
    icon: 'green',
    price: '€ 9.00',
  },
];

const BusinessPlanSolution4ListItems = [
  {
    text: 'Sales Order Management',
    icon: 'green',
    price: '€ 39.00',
  },
  {
    text: 'Purchase Order Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Invoice Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Accounts Payable Processing',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Warehouse Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Payments Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Pre-Accounting',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Task Management',
    icon: 'yellow',
    price: '€ 0.00',
  },
  {
    text: 'Customer Support',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Security And Access Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Production Management',
    icon: 'red',
    price: '€ 0.00',
  },
  {
    text: 'Client Management',
    icon: 'yellow',
    price: '€ 0.00',
  },
];

const EnterprisePlanSolution1ListItems = [
  {
    text: 'Buying Experience | Premium',
    icon: 'yellow',
    price: '€ 39.00',
  },
  {
    text: 'Product Search & Filtering',
    icon: 'green',
    price: '€ 0.00',
  },
  {
    text: 'Product Viewing',
    icon: 'green',
    price: '€ 0.00',
  },
  {
    text: 'Payment & Delivery | Standard',
    icon: 'yellow',
    price: '€ 49.00',
  },
  {
    text: 'Marketing Tools | Standard',
    icon: 'yellow',
    price: '€ 99.00',
  },
  {
    text: 'SEO Tools',
    icon: 'green',
    price: '€ 0.00',
  },
  {
    text: 'Analytics Tools',
    icon: 'green',
    price: '€ 0.00',
  },
];

const EnterprisePlanSolution2ListItems = [
  {
    text: 'Catalogue & Product Management | ?',
    icon: 'green',
    price: '€ 99.00',
  },
  {
    text: 'Product content | Tera',
    icon: 'yellow',
    price: '€ 249.00',
  },
  {
    text: 'Advanced Price Management',
    icon: 'green',
    price: '€ 9.00',
  },
];

const EnterprisePlanSolution3ListItems = [
  {
    text: 'More than 10 integrations with suppliers',
    icon: 'yellow',
    price: '€ 49.00',
  },
  {
    text: 'Integration with price comparison sites',
    icon: 'green',
    price: '€ 35.00',
  },
  {
    text: '3 integration with payment processing system',
    icon: 'yellow',
    price: '€ 27.00',
  },
  {
    text: 'Integration with PayPal',
    icon: 'green',
    price: '€ 9.00',
  },
  {
    text: 'Integration with finance leasing company',
    icon: 'green',
    price: '€ 9.00',
  },
  {
    text: 'Google Analytics integration',
    icon: 'green',
    price: '€ 9.00',
  },
  {
    text: '3 integrations with logistics partner',
    icon: 'yellow',
    price: '€ 18.00',
  },
  {
    text: 'Integration with ERP solution',
    icon: 'green',
    price: '€ 39.00',
  },
  {
    text: 'Integration with email marketing platform',
    icon: 'green',
    price: '€ 9.00',
  },
  {
    text: 'Facebook Integration',
    icon: 'green',
    price: '€ 9.00',
  },
];

const EnterprisePlanSolution4ListItems = [
  {
    text: 'Sales Order Management',
    icon: 'green',
    price: '€ 39.00',
  },
  {
    text: 'Purchase Order Management',
    icon: 'green',
    price: '€ 39.00',
  },
  {
    text: 'Invoice Management',
    icon: 'green',
    price: '€ 9.00',
  },
  {
    text: 'Accounts Payable Processing',
    icon: 'green',
    price: '€ 9.00',
  },
  {
    text: 'Warehouse Management',
    icon: 'green',
    price: '€ 39.00',
  },
  {
    text: 'Payments Management',
    icon: 'green',
    price: '€ 39.00',
  },
  {
    text: 'Pre-Accounting',
    icon: 'green',
    price: '€ 9.00',
  },
  {
    text: 'Task Management for 5 users',
    icon: 'yellow',
    price: '€ 9.00',
  },
  {
    text: 'Customer Support',
    icon: 'green',
    price: '€ 39.00',
  },
  {
    text: 'Security And Access Management',
    icon: 'green',
    price: '€ 9.00',
  },
  {
    text: 'Production Management',
    icon: 'green',
    price: '€ 9.00',
  },
  {
    text: 'Client Management for 3 users',
    icon: 'yellow',
    price: '€ 27.00',
  },
];

const IndexPage: FC = () => {
  const { t } = useTranslation();
  const [showFormModal, setShowFormModal] = useState(false);
  const [showHiddenFeatures, setShowHiddenFeatures] = useState(false);

  return (
    <Layout>
      <Hero>
        <div>
          <HeroHeading variant={HeadingVariant.ALFA}>
            <Trans i18nKey="index_main_heading" />
          </HeroHeading>
          <HeroList
            items={[
              { text: t('index_main_list_item_1') },
              { text: t('index_main_list_item_2') },
              { text: t('index_main_list_item_3') },
              { text: t('index_main_list_item_4') },
              { text: t('index_main_list_item_5') },
            ]}
          />
          <Price>
            <div>start from</div>
            <div>
              € <HeroPrice>0.00</HeroPrice> / mo.
            </div>
          </Price>
          <HeroButton
            onClick={() => setShowFormModal(true)}
            appearance={ButtonAppearance.ALFA}
          >
            <Trans i18nKey="common_start_now" />
          </HeroButton>
          <Modal
            isOpen={showFormModal}
            contentLabel={t('common_start_now')}
            onClose={() => setShowFormModal(false)}
          >
            <ContactForm />
          </Modal>
          <Icon icon={<NoCardIcon />}>
            <Trans i18nKey="common_try_for_free" />
          </Icon>
        </div>
        <HeroImage>
          <StaticImage
            src="../assets/images/instegral.jpg"
            alt={t('index_main_image_alt')}
            placeholder="blurred"
            quality={100}
            loading="eager"
          />
        </HeroImage>
      </Hero>
      <GreyBackground>
        <Section title={t('index_second_section_title')} textAlign="center">
          <StyledGrid columns={3}>
            <IconBlock
              icon={<RewindIcon />}
              title={t('index_second_section_first_icon_title')}
            >
              <p>
                <Trans i18nKey="index_second_section_first_icon_text_0" />
                <strong>
                  <Trans i18nKey="index_second_section_first_icon_text_1" />
                </strong>
              </p>
            </IconBlock>
            <IconBlock
              icon={<LampIcon />}
              title={t('index_second_section_second_icon_title')}
            >
              <p>
                <Trans i18nKey="index_second_section_second_icon_text" />
              </p>
            </IconBlock>
            <IconBlock
              icon={<GlobeIcon />}
              title={t('index_second_section_third_icon_title')}
            >
              <p>
                <Trans i18nKey="index_second_section_third_icon_text" />
              </p>
            </IconBlock>
          </StyledGrid>
        </Section>
      </GreyBackground>
      <DarkBackgroundSection>
        <Section
          title={t('index_third_section_title')}
          subtitle={t('index_third_section_subtitle')}
          isDark
        >
          <StyledGrid columns={3}>
            <IconBlock
              icon={<CodingIcon />}
              title={t('index_third_section_first_icon_title')}
              isOnDark
            >
              <p>
                <Trans i18nKey="index_third_section_first_icon_text" />
              </p>
            </IconBlock>
            <IconBlock
              icon={<ClockIcon />}
              title={t('index_third_section_second_icon_title')}
              isOnDark
            >
              <p>
                <Trans i18nKey="index_third_section_second_icon_text" />
              </p>
            </IconBlock>
            <IconBlock
              icon={<DesktopIcon />}
              title={t('index_third_section_third_icon_title')}
              isOnDark
            >
              <p>
                <Trans i18nKey="index_third_section_third_icon_text" />
              </p>
            </IconBlock>
          </StyledGrid>
          {showHiddenFeatures && (
            <StyledGrid columns={3}>
              <IconBlock
                icon={<ThunderIcon />}
                title={t('index_third_section_fourth_icon_title')}
                isOnDark
              >
                <p>
                  <Trans i18nKey="index_third_section_fourth_icon_text" />
                </p>
              </IconBlock>
              <IconBlock
                icon={<PCIcon />}
                title={t('index_third_section_fifth_icon_title')}
                isOnDark
              >
                <p>
                  <Trans i18nKey="index_third_section_fifth_icon_text" />
                </p>
              </IconBlock>
              <IconBlock
                icon={<MaintenanceIcon />}
                title={t('index_third_section_sixth_icon_title')}
                isOnDark
              >
                <p>
                  <Trans i18nKey="index_third_section_sixth_icon_text" />
                </p>
              </IconBlock>
              <IconBlock
                icon={<SecurityCircleIcon />}
                title={t('index_third_section_seventh_icon_title')}
                isOnDark
              >
                <p>
                  <Trans i18nKey="index_third_section_seventh_icon_text" />
                </p>
              </IconBlock>
            </StyledGrid>
          )}
          {!showHiddenFeatures && (
            <FeaturesButton
              withCaret
              appearance={ButtonAppearance.CHARLIE}
              onClick={() => setShowHiddenFeatures(true)}
            >
              More benefits
            </FeaturesButton>
          )}
        </Section>
      </DarkBackgroundSection>
      <Section
        title={t('index_fourth_section_title')}
        subtitle={t('index_fourth_section_subtitle')}
      >
        <Bundle />
      </Section>
      <GreyBackground>
        <Section title={t('common_plans')} textAlign="center">
          <Toggle
            firstToggleButton={t('common_paid_a_month')}
            secondToggleButton={t('common_paid_a_quarter')}
            firstToggleContent={
              <StyledGrid columns={3}>
                <PricePlan
                  title={t('common_starter')}
                  price="9.00"
                  buttonText={t('common_start_starter')}
                  supportText={t('common_per_month')}
                >
                  <Icon icon={<EuroIcon />}>
                    <Trans i18nKey="common_money_guarantee" />
                  </Icon>
                  <Separator />
                  <List
                    heading="Solution #1 | eCommerce panel V"
                    items={StarterPlanSolution1ListItems}
                  />
                  <List
                    heading="Solution #2 | Product data management V"
                    items={StarterPlanSolution2ListItems}
                  />
                  <List
                    heading="Solution #3 | Integrations V"
                    items={StarterPlanSolution3ListItems}
                  />
                  <List
                    heading="Solution #4 | Optimizer V"
                    items={StarterPlanSolution4ListItems}
                  />
                  <NoteWrapper>
                    <Icon icon={<MarkIcon />}>
                      <Trans i18nKey="common_can_add_feature" />
                    </Icon>
                  </NoteWrapper>
                </PricePlan>
                <PricePlan
                  title={t('common_business')}
                  price="49.00"
                  supportText={t('common_per_month')}
                  popular
                  buttonText={t('common_start_business')}
                >
                  <Icon icon={<EuroIcon />}>
                    <Trans i18nKey="common_money_guarantee" />
                  </Icon>
                  <Separator />
                  <List
                    heading="Solution #1 | eCommerce panel V"
                    items={BusinessPlanSolution1ListItems}
                  />
                  <List
                    heading="Solution #2 | Product data management V"
                    items={BusinessPlanSolution2ListItems}
                  />
                  <List
                    heading="Solution #3 | Integrations V"
                    items={BusinessPlanSolution3ListItems}
                  />
                  <List
                    heading="Solution #4 | Optimizer V"
                    items={BusinessPlanSolution4ListItems}
                  />
                  <NoteWrapper>
                    <Icon icon={<MarkIcon />}>
                      <Trans i18nKey="common_can_add_feature" />
                    </Icon>
                  </NoteWrapper>
                </PricePlan>
                <PricePlan
                  title={t('common_enterprise')}
                  price="495.00"
                  supportText={t('common_per_month')}
                  buttonText={t('common_start_enterprise')}
                >
                  <Icon icon={<EuroIcon />}>
                    <Trans i18nKey="common_money_guarantee" />
                  </Icon>
                  <Separator />
                  <List
                    heading="Solution #1 | eCommerce panel V"
                    items={EnterprisePlanSolution1ListItems}
                  />
                  <List
                    heading="Solution #2 | Product data management V"
                    items={EnterprisePlanSolution2ListItems}
                  />
                  <List
                    heading="Solution #3 | Integrations V"
                    items={EnterprisePlanSolution3ListItems}
                  />
                  <List
                    heading="Solution #4 | Optimizer V"
                    items={EnterprisePlanSolution4ListItems}
                  />
                  <NoteWrapper>
                    <Icon icon={<MarkIcon />}>
                      <Trans i18nKey="common_can_add_feature" />
                    </Icon>
                  </NoteWrapper>
                </PricePlan>
              </StyledGrid>
            }
            secondToggleContent={
              <StyledGrid columns={3}>
                <PricePlan
                  title={t('common_starter')}
                  price="8.10"
                  buttonText={t('common_start_starter')}
                  supportText={t('common_per_month')}
                  labelText="-10 %"
                >
                  <Icon icon={<EuroIcon />}>
                    <Trans i18nKey="common_money_guarantee" />
                  </Icon>
                  <Separator />
                  <List
                    heading="Solution #1 | eCommerce panel V"
                    items={StarterPlanSolution1ListItems}
                  />
                  <List
                    heading="Solution #2 | Product data management V"
                    items={StarterPlanSolution2ListItems}
                  />
                  <List
                    heading="Solution #3 | Integrations V"
                    items={StarterPlanSolution3ListItems}
                  />
                  <List
                    heading="Solution #4 | Optimizer V"
                    items={StarterPlanSolution4ListItems}
                  />
                </PricePlan>
                <PricePlan
                  title={t('common_business')}
                  price="44.10"
                  supportText={t('common_per_month')}
                  popular
                  buttonText={t('common_start_business')}
                  labelText="-10 %"
                >
                  <Icon icon={<EuroIcon />}>
                    <Trans i18nKey="common_money_guarantee" />
                  </Icon>
                  <Separator />
                  <List
                    heading="Solution #1 | eCommerce panel V"
                    items={BusinessPlanSolution1ListItems}
                  />
                  <List
                    heading="Solution #2 | Product data management V"
                    items={BusinessPlanSolution2ListItems}
                  />
                  <List
                    heading="Solution #3 | Integrations V"
                    items={BusinessPlanSolution3ListItems}
                  />
                  <List
                    heading="Solution #4 | Optimizer V"
                    items={BusinessPlanSolution4ListItems}
                  />
                </PricePlan>
                <PricePlan
                  title={t('common_enterprise')}
                  price="445.50"
                  supportText={t('common_per_month')}
                  buttonText={t('common_start_enterprise')}
                  labelText="-10 %"
                >
                  <Icon icon={<EuroIcon />}>
                    <Trans i18nKey="common_money_guarantee" />
                  </Icon>
                  <Separator />
                  <List
                    heading="Solution #1 | eCommerce panel V"
                    items={EnterprisePlanSolution1ListItems}
                  />
                  <List
                    heading="Solution #2 | Product data management V"
                    items={EnterprisePlanSolution2ListItems}
                  />
                  <List
                    heading="Solution #3 | Integrations V"
                    items={EnterprisePlanSolution3ListItems}
                  />
                  <List
                    heading="Solution #4 | Optimizer V"
                    items={EnterprisePlanSolution4ListItems}
                  />
                </PricePlan>
              </StyledGrid>
            }
          />
        </Section>
      </GreyBackground>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO title="Homepage | Instegral" />;
