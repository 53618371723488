import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Label from 'src/components/Label';
import { colors } from 'src/theme/colors';
import { device } from 'src/theme/breakpoints';
import ContactForm from '../ContactForm/ContactForm';
import Modal from '../Modal';

const Container = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 54px 40px 46px;
  position: relative;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;

  @media ${device.desktop} {
    font-size: 22px;
  }
`;

const Price = styled.div`
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 52px;
  justify-content: center;
  line-height: 1;
  margin: 0;

  @media ${device.desktop} {
    font-size: 65px;
  }
`;

const EuroSymbol = styled.div`
  font-size: 22px;
  font-weight: 500;
  margin-right: 8px;

  @media ${device.desktop} {
    font-size: 30px;
  }
`;

const StyledButton = styled.button<{ popular?: boolean }>`
  background-color: ${({ popular }) =>
    popular ? `${colors.green}` : `${colors.purple400}`};
  border: none;
  border-radius: 6px;
  color: ${colors.white};
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  height: 48px;
  margin: 40px auto;
  max-width: 280px;
  width: 100%;
`;

const SupportText = styled.p`
  color: ${colors.black};
  font-size: 12px;
  margin: 10px 0 0;
  text-align: center;
  text-transform: uppercase;

  @media ${device.desktop} {
    font-size: 14px;
  }
`;

const AttentionGrabber = styled.div`
  background-color: ${colors.green};
  border-radius: 28px;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 900;
  left: 50%;
  padding: 12px 72px;
  position: absolute;
  transform: translateX(-50%);
  top: -24px;
  width: max-content;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent; /* Adjust the size as needed */
    border-right: 12px solid transparent; /* Adjust the size as needed */
    border-top: 12px solid ${colors.green}; /* Triangle background color */
    bottom: -10px; /* Adjust this value for vertical positioning */
    left: 50%;
    transform: translateX(-50%);
  }

  @media ${device.desktop} {
    font-size: 20px;
  }
`;

interface PricePlanProps {
  title: string;
  labelText?: string;
  introText?: string;
  price?: string;
  supportText?: string;
  children: React.ReactNode;
  popular?: boolean;
  buttonText?: string;
  topButton?: string;
}

const PricePlan: FC<PricePlanProps> = ({
  title,
  labelText,
  supportText,
  price,
  popular,
  children,
  buttonText,
}) => {
  const [showFormModal, setShowFormModal] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Container>
      {popular && <AttentionGrabber>Most popular</AttentionGrabber>}
      <Header>
        <Title>{title}</Title>
        {labelText && <Label text={labelText} isGreen={popular} />}
      </Header>
      {price && (
        <Price>
          <EuroSymbol>€</EuroSymbol>
          {price}
        </Price>
      )}
      {supportText && <SupportText>{supportText}</SupportText>}

      {buttonText && (
        <StyledButton onClick={() => setShowFormModal(true)} popular={popular}>
          {buttonText}
        </StyledButton>
      )}
      {children}
      <Modal
        isOpen={showFormModal}
        contentLabel={t('common_start_now')}
        onClose={() => setShowFormModal(false)}
      >
        <ContactForm />
      </Modal>
    </Container>
  );
};

export default PricePlan;
