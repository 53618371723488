import React, { FC } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import { colors } from 'src/theme/colors';
import Heading, { HeadingScope, HeadingVariant } from 'src/components/Heading';
import List from 'src/components/List';
import Link, { LinkVariant } from 'src/components/Link';
import { LINKS } from 'src/utils/constants';
import { device } from 'src/theme/breakpoints';
import Grid from '../Grid';

const Wrapper = styled(Grid)`
  margin: 70px 0 64px;
`;

const BundleItem = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    @media ${device.mobileDOWN} {
      margin-top: 40px;
    }
  }
`;

const SupText = styled.span`
  color: ${colors.purple800};
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 14px;
  text-align: center;

  @media ${device.desktop} {
    font-size: 16px;
  }
`;

const BundleNumber = styled.span`
  align-items: center;
  align-self: center;
  background-color: ${colors.purple400};
  border-radius: 50%;
  color: ${colors.white};
  display: flex;
  font-size: 28px;
  justify-content: center;
  height: 62px;
  width: 62px;

  @media ${device.desktop} {
    height: 76px;
    font-size: 35px;
    width: 76px;
  }
`;

const BundleItemTitle = styled(Heading)`
  margin: 30px 0 34px;

  @media ${device.desktop} {
    margin: 36px 0 32px;
  }
`;

const BundleText = styled.p`
  font-size: 16px;
  margin: 0 0 24px;

  @media ${device.desktop} {
    font-weight: 700;
  }
`;

const BottomText = styled.p`
  font-size: 14px;
  margin: 24px 0;

  @media ${device.desktop} {
    font-size: 16px;
  }
`;

const UpperText = styled.p`
  margin: 0 0 24px;
`;

const StyledLink = styled(Link)`
  align-self: flex-start;
  font-size: 16px;
  margin-top: auto;
`;

const Bundle: FC = () => {
  const { t } = useTranslation();

  const firstBundleListItems = [
    { text: t('index_fourth_section_first_bundle_list_item_1') },
    { text: t('index_fourth_section_first_bundle_list_item_2') },
    { text: t('index_fourth_section_first_bundle_list_item_3') },
    { text: t('index_fourth_section_first_bundle_list_item_4') },
  ];

  const secondBundleListItems = [
    { text: t('index_fourth_section_second_bundle_list_item_1') },
    { text: t('index_fourth_section_second_bundle_list_item_2') },
    { text: t('index_fourth_section_second_bundle_list_item_3') },
    { text: t('index_fourth_section_second_bundle_list_item_4') },
  ];

  const thirdBundleListItems = [
    { text: t('index_fourth_section_third_bundle_list_item_1') },
    { text: t('index_fourth_section_third_bundle_list_item_2') },
    { text: t('index_fourth_section_third_bundle_list_item_3') },
    { text: t('index_fourth_section_third_bundle_list_item_4') },
    { text: t('index_fourth_section_third_bundle_list_item_5') },
  ];

  const fourthBundleListItems = [
    { text: t('index_fourth_section_fourth_bundle_list_item_1') },
    { text: t('index_fourth_section_fourth_bundle_list_item_2') },
    { text: t('index_fourth_section_fourth_bundle_list_item_3') },
    { text: t('index_fourth_section_fourth_bundle_list_item_4') },
    { text: t('index_fourth_section_fourth_bundle_list_item_5') },
  ];

  return (
    <HeadingScope>
      <Wrapper columns={4}>
        <BundleItem>
          <SupText>
            <Trans i18nKey="common_solution" />
          </SupText>
          <BundleNumber>#1</BundleNumber>
          <BundleItemTitle variant={HeadingVariant.CHARLIE}>
            <Trans i18nKey="index_fourth_section_first_bundle_title" />
          </BundleItemTitle>
          <BundleText>
            <Trans i18nKey="index_fourth_section_first_bundle_text_0" />
          </BundleText>
          <List items={firstBundleListItems} />
          <StyledLink
            variant={LinkVariant.ALFA}
            url={LINKS.PRODUCTS_ECCOMMERCE}
          >
            <Trans i18nKey="common_more" />
          </StyledLink>
        </BundleItem>
        <BundleItem>
          <SupText>
            <Trans i18nKey="common_solution" />
          </SupText>
          <BundleNumber>#2</BundleNumber>
          <BundleItemTitle variant={HeadingVariant.CHARLIE}>
            <Trans i18nKey="index_fourth_section_second_bundle_title" />
          </BundleItemTitle>
          <BundleText>
            <Trans i18nKey="index_fourth_section_second_bundle_text_0" />
          </BundleText>
          <List items={secondBundleListItems} />
          <BottomText>
            <Trans i18nKey="index_fourth_section_second_bundle_text_1" />
          </BottomText>
          <StyledLink variant={LinkVariant.ALFA} url={LINKS.PRODUCTS_DATA}>
            <Trans i18nKey="common_more" />
          </StyledLink>
        </BundleItem>
        <BundleItem>
          <SupText>
            <Trans i18nKey="common_solution" />
          </SupText>
          <BundleNumber>#3</BundleNumber>
          <BundleItemTitle variant={HeadingVariant.CHARLIE}>
            <Trans i18nKey="index_fourth_section_third_bundle_title" />
          </BundleItemTitle>
          <BundleText>
            <Trans i18nKey="index_fourth_section_third_bundle_text_0" />
          </BundleText>
          <List items={thirdBundleListItems} />
          <BottomText>
            <Trans i18nKey="index_fourth_section_third_bundle_text_1" />
          </BottomText>
          <StyledLink
            variant={LinkVariant.ALFA}
            url={LINKS.PRODUCTS_INTEGRATIONS}
          >
            <Trans i18nKey="common_more" />
          </StyledLink>
        </BundleItem>
        <BundleItem>
          <SupText>
            <Trans i18nKey="common_solution" />
          </SupText>
          <BundleNumber>#4</BundleNumber>
          <BundleItemTitle variant={HeadingVariant.CHARLIE}>
            <Trans i18nKey="index_fourth_section_fourth_bundle_title" />
          </BundleItemTitle>
          <BundleText>
            <Trans i18nKey="index_fourth_section_fourth_bundle_text_0" />
          </BundleText>
          <UpperText>
            <Trans i18nKey="index_fourth_section_fourth_bundle_text_1" />
          </UpperText>
          <List items={fourthBundleListItems} />
          <StyledLink variant={LinkVariant.ALFA} url={LINKS.PRODUCTS_OPTIMIZER}>
            <Trans i18nKey="common_more" />
          </StyledLink>
        </BundleItem>
      </Wrapper>
    </HeadingScope>
  );
};

export default Bundle;
