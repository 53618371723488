import React, { FC } from 'react';
import styled from 'styled-components';

import { colors } from 'src/theme/colors';
import { device } from 'src/theme/breakpoints';

const Container = styled.div<{ isGreen?: boolean }>`
  align-items: center;
  background-color: ${({ isGreen }) =>
    isGreen
      ? `rgba(${parseInt(colors.green.slice(1, 3), 16)}, ${parseInt(
          colors.green.slice(3, 5),
          16
        )}, ${parseInt(colors.green.slice(5, 7), 16)}, 0.3)`
      : `rgba(${parseInt(colors.purple400.slice(1, 3), 16)}, ${parseInt(
          colors.purple400.slice(3, 5),
          16
        )}, ${parseInt(colors.purple400.slice(5, 7), 16)}, 0.3)`};

  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  font-size: 12px;
  justify-content: center;
  height: 28px;
  padding: 0 16px;

  @media ${device.desktop} {
    font-size: 16px;
    height: 32px;
  }
`;

interface LabelProps {
  text: string;
  isGreen?: boolean;
}

const Label: FC<LabelProps> = ({ text, isGreen }) => (
  <Container isGreen={isGreen}>{text}</Container>
);

export default Label;
