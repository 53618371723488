import React, { FC } from 'react';
import styled from 'styled-components';

import Heading, { HeadingScope, HeadingVariant } from '../Heading';
import { colors } from 'src/theme/colors';
import Content from 'src/components/Content';
import { device } from 'src/theme/breakpoints';

const Subtitle = styled.p<SectionProps>`
  color: ${p => (p.isDark ? colors.white : colors.purple400)};
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 12px;

  @media ${device.mobileDOWN} {
    font-size: 14px;
  }

  @media ${device.tabletUP} {
    text-align: ${p => (p.textAlign ? p.textAlign : 'left')};
  }
`;

const Title = styled(Heading)<SectionProps>`
  color: ${p => (p.isDark ? colors.white : colors.purple800)};

  @media ${device.tabletUP} {
    text-align: ${p => (p.textAlign ? p.textAlign : 'left')};
  }
`;

const IntroText = styled.p<SectionProps>`
  margin: 0;
  color: ${p => (p.isDark ? colors.grey500 : colors.black)};

  @media ${device.tabletUP} {
    text-align: ${p => (p.textAlign ? p.textAlign : 'left')};
  }
`;

interface SectionProps {
  children: React.ReactNode;
  introText?: string;
  isDark?: boolean;
  isSmall?: boolean;
  textAlign?: string;
  title?: string;
  subtitle?: string;
}

const SectionProps: FC<SectionProps> = ({
  children,
  introText,
  isDark,
  isSmall,
  textAlign,
  title,
  subtitle,
}) => (
  <Content>
    <HeadingScope>
      {subtitle && (
        <Subtitle isDark={isDark} textAlign={textAlign}>
          {subtitle}
        </Subtitle>
      )}
      {title && (
        <Title
          variant={isSmall ? HeadingVariant.CHARLIE : HeadingVariant.BRAVO}
          isDark={isDark}
          textAlign={textAlign}
        >
          {title}
        </Title>
      )}
      {introText && (
        <IntroText isDark={isDark} textAlign={textAlign}>
          {introText}
        </IntroText>
      )}
      {children}
    </HeadingScope>
  </Content>
);

export default SectionProps;
